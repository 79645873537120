import { BlitzPage } from "@blitzjs/next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import Layout from "src/app/shop/layout/Layout"
import { FeatureGalleryPage } from "src/app/shop/feature-gallery/components/FeatureGalleryPage"

const ShopYearIndex: BlitzPage = () => {
  return <FeatureGalleryPage />
}

ShopYearIndex.getLayout = (page) => <Layout title="">{page}</Layout>

// export async function getStaticPaths() {
//   return {
//     // @TODO dynamic
//     paths: [
//       { params: { school: "blik", year: "2020" } },
//       { params: { school: "blik", year: "2021" } },
//       { params: { school: "tgroenschooltje", year: "2020" } },
//       { params: { school: "tgroenschooltje", year: "2021" } },
//     ],
//     fallback: false,
//   }
// }

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common", "shop"])),
      // Will be passed to the page component as props
    },
  }
}

export default ShopYearIndex
