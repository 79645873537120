import { Routes, useParam } from "@blitzjs/next"
import Link from "next/link"
import { Box, IconButton, Typography } from "@mui/material"
import ArrowExpandLeft from "mdi-material-ui/ArrowLeft"
import ArrowExpandRight from "mdi-material-ui/ArrowRight"
import { currentSchoolYear } from "src/app/core/libs/dateTime"
import { useActiveSchoolYear } from "../../shared/hooks/useActiveSchoolYear"

export const YearNavigator = () => {
  const school = useParam("school", "string") ?? ""
  const schoolYear = useActiveSchoolYear()

  return (
    <Box display="flex" alignItems="center" mb={4}>
      {schoolYear > 2020 && (
        <Link href={Routes.ShopYearIndex({ school, year: schoolYear - 1 })} passHref legacyBehavior>
          <IconButton size="small">
            <ArrowExpandLeft fontSize="small" />
          </IconButton>
        </Link>
      )}
      <Typography variant="h2">
        {schoolYear} - {schoolYear + 1}
      </Typography>
      {schoolYear < currentSchoolYear() && (
        <Link href={Routes.ShopYearIndex({ school, year: schoolYear + 1 })} passHref legacyBehavior>
          <IconButton size="small">
            <ArrowExpandRight fontSize="small" />
          </IconButton>
        </Link>
      )}
    </Box>
  )
}
