import { Alert, Link as MuiLink } from "@mui/material"
import HandWaveOutlineIcon from "mdi-material-ui/HandWaveOutline"
import Link from "next/link"
import { Routes, useParam } from "@blitzjs/next"
import { useMutation, useQuery } from "@blitzjs/rpc"
import getWelcomeMessageRead from "../queries/getWelcomeMessageRead"
import { Trans } from "react-i18next"
import { useSchoolConfig } from "../../shared/hooks/useSchoolConfig"
import setWelcomeMessageRead from "../mutations/setWelcomeMessageRead"

export const FeatureWelcomeMsg = () => {
  const [welcomeMessageRead, { refetch }] = useQuery(
    getWelcomeMessageRead,
    {},
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )
  const [updateWelcomeMessageRead] = useMutation(setWelcomeMessageRead)
  const schoolConfig = useSchoolConfig()
  const school = useParam("school", "string") || ""

  if (welcomeMessageRead || !schoolConfig?.welcomeMessage) return null

  return (
    <Alert
      severity="success"
      sx={{ mt: 4 }}
      icon={<HandWaveOutlineIcon />}
      onClose={() => {
        void updateWelcomeMessageRead({ welcomeMessageRead: true }).then(() => refetch({}))
      }}
    >
      <Trans
        defaults={schoolConfig.welcomeMessage}
        components={{
          nextlink: <Link href={Routes.ShopFAQPage({ school })} passHref />,
          muilink: <MuiLink />,
        }}
      />
    </Alert>
  )
}
