
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[school]/[year]",
      function () {
        return require("private-next-pages/[school]/[year]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[school]/[year]"])
      });
    }
  