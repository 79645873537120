import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import React, { useState } from "react"
import { Student } from "db"
import {
  CircularProgress,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
  Popover,
} from "@mui/material"
import setActiveStudent from "src/app/shop/shared/mutations/setActiveStudent"
import ChevronDown from "mdi-material-ui/ChevronDown"
import { Name } from "./StudentSelector.styles"
import getActiveStudent from "../../shared/queries/getActiveStudent"

type Props = {
  student: Student
  siblings: Student[]
}
export const StudentSelector = ({ student, siblings }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [setActiveStudentMutation, { isLoading }] = useMutation(setActiveStudent)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickSibling =
    (siblingId: number): ListItemButtonProps["onClick"] =>
    (event) => {
      event.preventDefault()
      void setActiveStudentMutation({ studentId: siblingId }).then(() => {
        void invalidateQuery(getActiveStudent)
      })
      setAnchorEl(null)
    }

  return (
    <>
      <Name onClick={handleClick}>
        {student.name} <ChevronDown sx={{ ml: 1 }} />
        {isLoading && <CircularProgress color="inherit" sx={{ opacity: 0.2, ml: 1 }} />}
      </Name>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        elevation={5}
      >
        <List>
          {siblings.map((sibling) => (
            <ListItemButton key={sibling.id} onClick={handleClickSibling(sibling.id)}>
              <ListItemText primary={sibling.name} />
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </>
  )
}
