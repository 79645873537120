import { FC, useEffect, useRef, useState } from "react"
import { Divider, Box, IconButton, Tooltip } from "@mui/material"
import UnfoldMoreHorizontal from "mdi-material-ui/UnfoldMoreHorizontal"
import UnfoldLessHorizontal from "mdi-material-ui/UnfoldLessHorizontal"
import useDisclosure from "../hooks/useDisclosure"
import { useTranslation } from "next-i18next"

type Props = {
  closedHeight: number | string
  enableCurtain?: boolean
}

export const Curtain: FC<Props> = ({ children, closedHeight, enableCurtain = true }) => {
  const { isOpen, onToggle } = useDisclosure()
  // const [enableCurtain, setEnableCurtain] = useState(true)
  const contentRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation("common")

  // Disabled for now: While images are loading, the height is not correct
  // useEffect(() => {
  //   const clientHeight = contentRef.current?.children[0]?.clientHeight ?? 0
  //   setEnableCurtain(clientHeight > closedHeight)
  // }, [closedHeight])

  return (
    <>
      <div style={{ height: isOpen ? "auto" : closedHeight, overflow: "hidden" }} ref={contentRef}>
        {children}
      </div>

      {enableCurtain && (
        <Box
          mt={0}
          mb={0}
          pt={1}
          pb={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={onToggle}
        >
          <Divider sx={{ width: "100%" }} />

          <Tooltip
            title={t<string>(isOpen ? "showLess" : "showMore")}
            arrow
            PopperProps={{
              style: { marginTop: -12 },
            }}
          >
            <IconButton
              color="primary"
              aria-label="Unfold"
              sx={{
                marginTop: "-20px",
                bgcolor: "background.default",
                boxShadow: 1,
                "&:hover": {
                  bgcolor: "background.default",
                },
              }}
            >
              {isOpen ? <UnfoldLessHorizontal /> : <UnfoldMoreHorizontal />}
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </>
  )
}
