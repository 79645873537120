import { Alert, Typography, Grid, Box } from "@mui/material"
import { useTranslation } from "next-i18next"
import { PageContainer } from "src/app/core/components/PageContainer"
import { CloudPhoto } from "src/app/core/components/CloudPhoto"
import { Curtain } from "src/app/core/components/Curtain"
import { useLightBox } from "src/app/core/hooks/useLightBox"
import { Gallery } from "src/app/core/components/Gallery"
import { useActiveStudent } from "../../shared/hooks/useActiveStudent"
import { useYearCollection } from "../../shared/hooks/useYearCollection"
import { FeatureProducts } from "../../feature-products/components/FeatureProducts"
import { useActiveSchoolYear } from "../../shared/hooks/useActiveSchoolYear"
import { PhotoDialog } from "../../feature-photo-dialog/components/PhotoDialog"
import { StudentSelector } from "./StudentSelector"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"
import { sortYearCollection } from "../../shared/util"
import { Student, SchoolGroup } from "db"
import { Fragment, useState } from "react"
import { StudentSwitch } from "./StudentSwitch"
import { FeatureWelcomeMsg } from "../../feature-welcome-msg/components/FeatureWelcomeMsg"
import { YearNavigator } from "./YearNavigator"
import { useSchoolConfig } from "../../shared/hooks/useSchoolConfig"

// TODO: configuration per school?
enum Layout {
  Classic = "classic",
  Sections = "sections",
}
const sections = {
  highlight: ["portret", "mijn klas en ik"],
  named: [
    {
      name: (student: Student, group: SchoolGroup | null) => `De familie van ${student.name}`,
      variations: ["broer-zus"],
      curtainThreshold: 10,
    },
    {
      name: (student: Student, group: SchoolGroup | null) =>
        `De klas van ${student.name}, ${group?.name}`,
      variations: ["mijn klas", "mijn klas en leerkracht", "ditl"],
      curtainThreshold: 10,
    },
  ],
}
const layout = Layout.Sections

export const FeatureGalleryPage = () => {
  const { t } = useTranslation("shop")
  const student = useActiveStudent()
  const schoolYear = useActiveSchoolYear()
  const yearCollection = useYearCollection(student.id, schoolYear)
  const lightBox = useLightBox()
  const siblings = student.siblings?.students.filter((s) => s.id !== student.id) || []
  const photos = sortYearCollection(yearCollection)
  const [lightBoxPhotos, setLightBoxPhotos] = useState<typeof photos>([])
  const schoolConfig = useSchoolConfig()

  const siblingSwitch = <StudentSwitch student={student} />

  if (!yearCollection.length || !photos.length) {
    return (
      <PageContainer>
        {siblingSwitch}
        <Alert severity="info" sx={{ mt: 4, mb: 4 }}>
          {t("alertNoPhotosYet")}{" "}
          {siblings.length > 0 ? (
            <StudentSelector student={student} siblings={siblings} />
          ) : (
            student.name
          )}
        </Alert>
        <YearNavigator />
      </PageContainer>
    )
  }
  if (layout === Layout.Sections) {
    const highlightSectionPhotos = photos.filter((photo) =>
      sections.highlight.includes(photo.variation?.name.toLowerCase() ?? "")
    )

    return (
      <DndProvider backend={HTML5Backend}>
        <PageContainer>
          <FeatureWelcomeMsg />
          {siblingSwitch}

          <Typography variant="h1" sx={{ mt: 4 }}>
            {t("studentTitle")} {student.name}
          </Typography>

          <YearNavigator />

          <Grid container spacing={2}>
            {highlightSectionPhotos.map((photo, index) => {
              const ratio = (photo.width || 1) / (photo.height || 1)
              return (
                <Grid
                  item
                  key={photo.id}
                  onClick={() => {
                    setLightBoxPhotos(highlightSectionPhotos)
                    lightBox.onOpen(index)
                  }}
                >
                  <CloudPhoto
                    photo={photo}
                    updatedAt={photo.updatedAt.toISOString()}
                    highRes={photo.highRes}
                    transformation={"fos_large_watermark"}
                    height={400}
                    width={ratio * 400}
                    style={{ borderRadius: 16 }}
                  />
                </Grid>
              )
            })}
          </Grid>

          {sections.named.map(({ name, variations, curtainThreshold }, index) => {
            const sectionPhotos = photos.filter((photo) =>
              variations.includes(photo.variation?.name.toLowerCase() ?? "")
            )

            if (sectionPhotos.length === 0) return null

            return (
              <Fragment key={index}>
                <Typography variant="h2" sx={{ mb: 2, mt: 2 }} fontWeight="600">
                  {name(student, student.schoolGroup)}{" "}
                  {sectionPhotos.length > 10 &&
                    `(${t("numPhotos", { count: sectionPhotos.length })})`}
                </Typography>
                <Curtain
                  closedHeight={sectionPhotos.length > curtainThreshold ? 260 : "auto"}
                  key={student.id}
                  enableCurtain={sectionPhotos.length > curtainThreshold}
                >
                  <Gallery
                    photos={sectionPhotos}
                    onClick={(index) => {
                      setLightBoxPhotos(sectionPhotos)
                      lightBox.onOpen(index)
                    }}
                  />
                </Curtain>
              </Fragment>
            )
          })}

          <Typography variant="h2" sx={{ mt: 4 }} fontWeight="600">
            {t("orderTitle")}
          </Typography>

          <Box mt={4} pb={16}>
            <FeatureProducts />
          </Box>

          {lightBox.isOpen && (
            <PhotoDialog
              photos={lightBoxPhotos}
              activeIndex={lightBox.index}
              onClose={lightBox.onClose}
              enableDownload={schoolConfig?.useFreeDownloads ?? false}
            />
          )}
        </PageContainer>
      </DndProvider>
    )
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <PageContainer>
        <Typography variant="h1" sx={{ mt: 4 }}>
          {t("studentTitle")}{" "}
          {siblings.length > 0 ? (
            <StudentSelector student={student} siblings={siblings} />
          ) : (
            student.name
          )}
        </Typography>

        <Typography variant="h2" sx={{ mb: 4 }}>
          {schoolYear} - {schoolYear + 1}
        </Typography>

        <Curtain closedHeight={260} key={student.id}>
          <Gallery photos={photos} onClick={(index) => lightBox.onOpen(index)} />
        </Curtain>

        <Typography variant="h2" sx={{ mt: 4 }} fontWeight="600">
          {t("orderTitle")}
        </Typography>

        <Box mt={4} pb={16}>
          <FeatureProducts />
        </Box>

        {lightBox.isOpen && (
          <PhotoDialog photos={photos} activeIndex={lightBox.index} onClose={lightBox.onClose} />
        )}
      </PageContainer>
    </DndProvider>
  )
}
